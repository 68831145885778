import { graphql, useStaticQuery } from 'gatsby'

export default function useSiteMetadata() {
    const {
        site: { siteMetadata }
    } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          descriptions {
            en
            nl
          }
          shortTitle
          siteUrl
        }
      }
    }
  `)
    return siteMetadata
}
